// @ts-nocheck
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonList,
  IonLabel,
  IonItemDivider,
  IonItemGroup,
  IonItem,
  IonRow,
  IonGrid,
  IonCol,
  IonMenu,
  IonMenuButton,
  IonButtons,
  IonMenuToggle,
  IonModal,
  IonInput,
  IonLoading,
  useIonToast,
  IonNote,
  IonSearchbar,
  IonFabButton,
  IonSegmentButton,
  IonSegment,
  IonIcon,
  IonFab,
  IonFabList,
} from "@ionic/react";
import { ellipsisHorizontalOutline } from "ionicons/icons";
import ExploreContainer from "../components/ExploreContainer";
import { APP_VERSION } from "../config";
import useStore from "../store";
import axios from "axios";
import { useState, useEffect } from "react";
import { Virtuoso } from "react-virtuoso";

interface Partner {
  nome: string;
  cognome: string;
  indirizzo: string;
  citta: string;
  cap: string;
  provincia: string;
}

const initialState = {
  nome: "",
  cognome: "",
  indirizzo: "",
  citta: "",
  cap: "",
  provincia: "",
};

const AddDestination: React.FC = ({ match }: any) => {
  const { user, partners } = useStore();
  const [showModal, setShowModal] = useState(false);
  const [showLoadingOrder, setShowLoadingOrder] = useState(false);
  const [missingField, setMissingField] = useState(false);
  const [partner, setPartner] = useState<Partner>(initialState);
  const [present] = useIonToast();

  const cliente = partners.find(
    (partner: { id: string }) => String(partner.id) === match.params.id
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setPartner((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(partner);
  };

  const isFieldEmpty = (fieldName: keyof Partner) => {
    return partner[fieldName].trim() === "";
  };

  function checkObjectProperties(partner: any) {
    for (const key in partner) {
      if (
        partner.hasOwnProperty(key) &&
        typeof partner[key] === "string" &&
        partner[key].trim() === ""
      ) {
        return false;
      }
    }
    return true;
  }

  function valida() {
    if (checkObjectProperties(partner)) {
      console.log("All object properties are non-empty strings");
      inviaAnagrafica();
    } else {
      console.log("At least one object property is an empty string");
      present({
        message: "Alcuni campi non sono completi",
        duration: 2500,
        position: "top",
      });
    }
  }

  // Map each matching destination to an IonItem component with an IonLabel containing the destination name

  const inviaAnagrafica = () => {
    setShowLoadingOrder(true);
    console.log(process.env);
    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Bioservice Srl",
            email: "info@bioservicesrl.eu",
          },
          to: [
            {
              email: "info@bioservicesrl.eu",
              name: `${user.name}`,
            },
          ],
          cc: [
            {
              email: `${user.username}`,
            },
          ],
          subject: "Richiesta di aggiunta Destinazione Aggiuntiva",
          htmlContent: `
          <html>
            <head></head>
            <body>
              <h2>Nuova Richiesta di aggiunta Destinazione Aggiuntiva</h2>
              <h3>Cliente: ${cliente.name}, ${cliente.street}, ${cliente.city}, ${cliente.zip}</h3>
              <p>Di seguito i dati per l'aggiunta della nuova destinazione:</p>
              <p>Nome: ${partner.nome}</p>
              <p>Cognome: ${partner.cognome}</p>
              <p>Indirizzo: ${partner.indirizzo}</p>
              <p>Città: ${partner.citta}</p>
              <p>CAP: ${partner.cap}</p>
              <p>Provincia: ${partner.provincia}</p>
              <p>Agente: ${user.name}</p>
            </body>
          </html>
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": `${process.env.REACT_APP_SENDINBLUE_KEY}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setShowLoadingOrder(false);
        setPartner(initialState);
        setShowModal(false);
        present({
          message:
            "Destinazione inviata con successo, riceverai una mail di conferma",
          duration: 3000,
          position: "top",
        });
      })
      .catch((error) => {
        setShowLoadingOrder(false);
        console.log(error);
        if (error.response.status === 401) {
          present({
            message: "Errore di autenticazione, controlla le credenziali",
            duration: 2500,
            position: "top",
          });
        } else {
          present({
            message: "Errore, controlla la connessione e riprova",
            duration: 2500,
            position: "top",
          });
        }
        setShowModal(false);
      });
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Cliente n°{match.params.id}</IonTitle>
            <IonButtons slot='start'>
              <IonButton routerLink='/clienti' routerDirection='back'>
                Indietro
              </IonButton>
            </IonButtons>
            <IonButtons slot='end'>
              <IonButton>
                <IonIcon icon={ellipsisHorizontalOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Cliente:</IonLabel>
            </IonItemDivider>
            <IonItem lines='full'>
              <IonLabel>
                <h2>{cliente.name}</h2>
                <p>
                  {cliente.street}, {cliente.city} , {cliente.zip}
                </p>
              </IonLabel>
            </IonItem>
          </IonItemGroup>
          <IonItemDivider>
            <IonLabel>Informazioni destinazione:</IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel position='floating'>Nome</IonLabel>
            <IonInput
              type='text'
              placeholder='Inserisci il nome o la ragione sociale'
              name='nome'
              value={partner.nome}
              onIonChange={handleChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>Cognome</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi il cognome'
              name='cognome'
              value={partner.cognome}
              onIonChange={handleChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>Indirizzo</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi Indirizzo'
              name='indirizzo'
              value={partner.indirizzo}
              onIonChange={handleChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>Città</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi Città'
              name='citta'
              value={partner.citta}
              onIonChange={handleChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>CAP</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi CAP'
              name='cap'
              value={partner.cap}
              onIonChange={handleChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>Provincia</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi Provincia'
              name='provincia'
              value={partner.provincia}
              onIonChange={handleChange}
            />
          </IonItem>
        </IonContent>
        <IonButton expand='block' fill='outline' onClick={() => valida()}>
          Invia Nuova Destinazione
        </IonButton>
      </IonPage>
      <IonLoading
        isOpen={showLoadingOrder}
        message={"Perfavore attendi, sto inviando l ordine..."}
      />
    </>
  );
};

export default AddDestination;
