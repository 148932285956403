import {
  IonItem,
  IonList,
  IonAccordion,
  IonLabel,
  IonAccordionGroup,
  IonButton,
  useIonActionSheet,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React, { useState } from "react";

// CategoryList Component
const CategoryList = ({ categories, onSelectCategory, children }) => {
  const [present] = useIonActionSheet();
  const [result, setResult] = useState();

  return (
    <>
      <div className="container">
        <IonButton
          onClick={() =>
            present({
              header: "Scegli un impianto",
              buttons: [
                {
                  text: "Delete",
                  role: "destructive",
                  data: {
                    action: "delete",
                  },
                },
                {
                  text: "Share",
                  data: {
                    action: "share",
                  },
                },
                {
                  text: "Cancel",
                  role: "cancel",
                  data: {
                    action: "cancel",
                  },
                },
              ],
              onDidDismiss: ({ detail }) => setResult(detail),
            })
          }
        >
          Open
        </IonButton>
        {result && <code>{JSON.stringify(result, null, 2)}</code>}
      </div>
      <IonList>
        {categories
          .filter((category) => category.parent_id === false)
          .map((category) => (
            <IonItem
              key={category.id}
              onClick={() => onSelectCategory(category.id)}
            >
              {category.display_name}
            </IonItem>
          ))}
      </IonList>
      <IonAccordionGroup>
        <IonAccordion value="first">
          <IonItem slot="header" color="light">
            <IonLabel>First Accordion</IonLabel>
          </IonItem>

          <IonItem>
            <div slot="content">
              <IonSelect placeholder="Select fruit">
                <IonSelectOption value="apples">Apples</IonSelectOption>
                <IonSelectOption value="oranges">Oranges</IonSelectOption>
                <IonSelectOption value="bananas">Bananas</IonSelectOption>
              </IonSelect>
            </div>
          </IonItem>
        </IonAccordion>
        <IonAccordion value="second">
          <IonItem slot="header" color="light">
            <IonLabel>Second Accordion</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            Second Content
          </div>
        </IonAccordion>
        <IonAccordion value="third">
          <IonItem slot="header" color="light">
            <IonLabel>Third Accordion</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            Third Content
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </>
  );
};

export default CategoryList;
