// @ts-nocheck
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonList,
  IonLabel,
  IonItemDivider,
  IonItemGroup,
  IonItem,
  IonRow,
  IonGrid,
  IonCol,
  IonMenu,
  IonMenuButton,
  IonButtons,
  IonMenuToggle,
  IonModal,
  IonInput,
  IonLoading,
  useIonToast,
  IonNote,
  IonSearchbar,
  IonNav,
  IonNavLink,
} from "@ionic/react";
import ClientPage from "../pages/ClientPage";
import { logOut } from "ionicons/icons";
import ExploreContainer from "../components/ExploreContainer";
import { APP_VERSION } from "../config";
import "./Tab4.css";
import useStore from "../store";
import axios from "axios";
import { useState } from "react";
import { userInfo } from "os";
import { Virtuoso } from "react-virtuoso";
import Fuse from "fuse.js";
import React from "react";

const Tab4: React.FC = () => {
  const { logout, user, partners } = useStore();
  const [showLoadingOrder, setShowLoadingOrder] = useState(false);

  const filterPartners = partners.filter((d: any) => d.parent_id === false);

  const [results, setResults] = useState([...filterPartners]);

  const handleChange = (ev: Event) => {
    const fuse = new Fuse(filterPartners, {
      keys: ["name"],
      threshold: 0.3,
    });
    const target = ev.target as HTMLIonSearchbarElement;
    const query = target.value?.trim().toLowerCase() || "";
    if (query === "") {
      setResults([...filterPartners]);
    } else {
      const searchResults = fuse.search(query);
      const items = searchResults.map((r) => r.item);
      setResults(items);
    }
  };
  


  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Clienti</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar onIonChange={(ev) => handleChange(ev)}></IonSearchbar>
          <Virtuoso
            style={{ height: "100%" }}
            data={results}
            itemContent={(index, results) => (
              <IonItem
                routerLink={`/clienti/${results.id}`}
                routerDirection='forward'
              >
                <IonLabel>
                  <h2 style={{ whiteSpace: 'normal' }}>{results.name}</h2>
                </IonLabel>
              </IonItem>
            )}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Tab4;
